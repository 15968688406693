<template>
    <banner1 />
    <banner2 />
    <banner7 />
    <banner3 />
</template>
  
<script>
import banner1 from './hardware_x_body_banner1.vue';
import banner2 from './hardware_x_body_banner2.vue';
import banner3 from './hardware_x_body_banner3.vue';

import banner7 from './hardware_x_body_banner7.vue';

export default {
  components: {
    banner1,
    banner2,
    banner3,
    banner7,
  },
  setup() {

  }
}
</script>
  