<template>
    <div class="container">
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '100%',
          padding: isMobile ? '10px' : '0px',
          height: isMobile ? '700px' : '500px',
          borderRadius: isMobile ? '0px' : '0px',
          marginTop: isMobile ? '0px' : '0px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #DBEDF7;
        "
        elevation="0"
      >
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <img src="/images/hardware_x/hardware_x_loading.jpg" :width="isMobile ? '500' : '600'">
        </div>
  
        <!-- Text section (50% width) -->
        <div class="text-section" style="flex: 1;">
          <div
          :style="{
            fontSize: isMobile ? '30px' : '55px',
            fontWeight: isMobile ? '900' : '900',
          }">
            하드웨어 X
          </div>
            <div
              style="color: grey; font-size: 13px;"
            >
              버전정보 : 1.0.1 / 2025-04-01
            </div>
            <div
              :style="{
                fontSize: isMobile ? '15px' : '16px',
                fontWeight: isMobile ? '100' : '500',
              }"
            >
              HardWareX 는 시스템의 하드웨어 정보를 수집하여 정보를 한눈에 확인할 수 간편한 툴입니다.
            </div><br>
            <div
              :style="{
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: isMobile ? '100' : '300',
              }"
            >
              CPU, 메인보드, RAM, 그래픽 카드, 디스크 및 운영체제 등
              <br>시스템을 구성하는 주요 장치 정보를 수집하는 무료 소프트웨어입니다.
              <br>또한, 현재 시스템의 네트워크 정보를 손쉽게 확인할 수 있습니다.
            </div>
            <v-btn
              color="#0066CC"
              style="color: white; margin-top: 20px;"
              :height="isMobile ? '40' : '50'"
              :width="isMobile ? '150' : '200'"
              @click="downloadSoftwareInstall"
            >
              무료다운로드 (설치형)
            </v-btn>
            <v-btn
              variant="outlined"
              color="#0066CC"
              style="color: #0066CC; margin-top: 20px; margin-left: 10px; border: 2px solid #0066CC;"
              :height="isMobile ? '40' : '50'"
              :width="isMobile ? '150' : '200'"
              @click="downloadSoftwarePortable"
            >
              무료다운로드 (Portable)
            </v-btn>
        </div>
      </v-card>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';
  
  export default {
    setup() {
      const router = useRouter();
      const isMobile = ref(false);
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const goToestimate = () => {
        router.push('/estimate');
      }

      const downloadSoftwarePortable = () => {
        window.open('https://cdn.samjinnetworks.com/software/hardware_x/HardWareX 1.0.1.exe', '_blank');
      }

      const downloadSoftwareInstall = () => {
        window.open('https://cdn.samjinnetworks.com/software/hardware_x/HardWareX Setup 1.0.1.exe', '_blank');
      }

      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        goToestimate,
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
        downloadSoftwarePortable,
        downloadSoftwareInstall,
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 0px 20px 0 20px;
    }
  }
  </style>
  