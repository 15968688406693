<template>
  <v-card
    class="card"
    :style="{
      width: isMobile ? '100%' : '100%',
      height: isMobile ? 'max-content' : 'imgHeight',
      flexDirection: isMobile ? 'column' : 'column',
      alignItems: 'start'
    }"
    style="
    background-color: white;
    display: flex;
    justify-content: center;
    "
    elevation="0"
  >

    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '100%',
        height: isMobile ? '50px' : '50px',
        borderRadius: isMobile ? '0px' : '15px',
        alignItems: 'center'
      }"
      style="
      background-color: #f9f9f9;
      display: flex;
      justify-content: center;
      "
      elevation="0"
    >
    <v-tabs
        bg-color="#f9f9f9"
        center-active
      >
        <v-tab @click="selectCategoryChange('1.')">CPU</v-tab>
        <v-tab @click="selectCategoryChange('2.')">CPU쿨러</v-tab>
        <v-tab @click="selectCategoryChange('3.')">메인보드</v-tab>
        <v-tab @click="selectCategoryChange('4.')">메모리</v-tab>
        <v-tab @click="selectCategoryChange('7.')">그래픽카드</v-tab>
        <v-tab @click="selectCategoryChange('5. SSD')">SSD</v-tab>
        <v-tab @click="selectCategoryChange('5. HDD')">HDD</v-tab>
        <v-tab @click="selectCategoryChange('8.')">파워서플라이</v-tab>
        <v-tab @click="selectCategoryChange('6.')">ODD(CD/DVD)</v-tab>
        <v-tab @click="selectCategoryChange('9.')">케이스</v-tab>
        <v-tab @click="selectCategoryChange('쿨러 - 케이스')">쿨링팬</v-tab>
        <v-tab @click="selectCategoryChange('모니터 - ')">모니터</v-tab>
        <v-tab @click="selectCategoryChange('프린터 - ')">프린터</v-tab>
        <v-tab @click="selectCategoryChange('노트북 - ')">노트북</v-tab>
        <v-tab @click="selectCategoryChange('99. 소프트웨어')">소프트웨어</v-tab>
        <v-tab @click="selectCategoryChange('99.조립')">조립</v-tab>
        <v-tab @click="selectCategoryChange('98.유지보수')">유지보수</v-tab>
      </v-tabs>
    </v-card>


    <v-card
      :height="imgHeight"
      style="
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      "
      :style="{
        width: isMobile ? '100%' : '100%',
        flexDirection: isMobile ? 'column' : 'row',
      }"
      elevation="0"
    >
      <v-card
        v-if="!goToCart"
        :height="imgHeight"
          class="card"
          elevation="3"
          style="
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
          "
          :style="{
            width: isMobile ? '100%' : '65%',
            margin: isMobile ? '0 0 0 5px' : '5px 5px 5px 5px',
            alignItems: 'flex-end',
          }"
          >
            <v-card-text
              style="
                display: flex;
                align-items: center;
                border-bottom: 1px #E0E0E0 solid;
                justify-content: center;
              "
              :style="{
                width: isMobile ? '100%' : '100%',
                backgroundColor: isMobile ? '#333333' : '',
              }"
              >
              <div v-if="!loading && !isMobile">
                <p style="font-size:17px; font-weight: 900; padding-right: 5px;"> {{ categoryNm }} </p> 상품갯수 : {{ categoryCount }}개
              </div>
              <v-spacer v-if="!isMobile"></v-spacer>
              <div width="330px">
                <v-text-field
                  v-model="itemSearch"
                  clear-icon="mdi-close-circle"
                  append-inner-icon="mdi-magnify"
                  clearable
                  density="compact"
                  label="상품명을 입력하세요"
                  variant="solo"
                  hide-details
                  single-line
                  @click:append-inner="onClick"
                  :style="{
                    width: isMobile ? '320px' : '400px',
                  }
                  "
                >
                  <!-- Prepend Icon -->
                  <template v-slot:prepend>
                    <div
                      @click="stockCheckChange"
                      :style="{ cursor: 'pointer', color: isMobile ? 'white' : 'black' }" 
                      v-if="userPermission === '0'">
                      <v-icon
                        :color="stockCheck ? 'primary' : 'grey'" 
                      >
                        {{ stockCheckIcon }}
                      </v-icon>
                      빠른
                    </div>
                    <div
                      @click="usedCheckChange"
                      :style="{ cursor: 'pointer', color: isMobile ? 'white' : 'black' }" 
                      v-if="!usedCheck">
                      <v-icon 
                        :color="usedCheck ? 'primary' : 'grey'" 
                      >
                        {{ usedCheckIcon }}
                      </v-icon>
                      중고
                    </div>
                    <div
                      @click="usedCheckChange"
                      :style="{ cursor: 'pointer', color: isMobile ? 'white' : 'black' }" 
                      v-if="usedCheck">
                      <v-icon 
                        :color="!usedCheck ? 'primary' : 'grey'"
                        icon="mdi-checkbox-blank-outline"
                      >
                      </v-icon>
                      새상품
                    </div>
                  </template>
                </v-text-field>
              </div>
              <v-spacer v-if="isMobile"></v-spacer>
              <v-btn v-if="isMobile" style="padding: 8px; min-width: auto; border: 1px solid grey; background-color: #333333;" elevation="0" @click="mobile_goToCart">
                <v-icon icon="mdi-cart" size="large" color="white"></v-icon>
                <v-icon icon="mdi-arrow-right" size="large" color="white"></v-icon>
              </v-btn>
            </v-card-text>

            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filterSearchQuery"
              item-key="id"
              id="items-data-table"
              class="styled-table"
              :items-per-page="itemsPage"
              :loading="loading"
              loading-text="데이터를 수집중입니다"
              @click:row="fetchDataItemOnClick"
              style="overflow-y: auto; height: 4000px;"
              itemsPerPageText=""
            >

              <template v-slot:headers>
              </template>


              <template v-slot:[`item.item_image_link`]="{ item }">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img :src="item.item_image_link" alt="Product Image" width="80" height="80" @click="openImage(item.item_nm, item.item_detail, item.item_image_link, item.item_detail_image_link)"
                style="cursor: pointer;"
                />
              </div>
              </template>


              <template v-slot:[`item.item_nm`]="{ item }">
                <div
                  style="
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: start;
                    flex-direction: column;
                  "
                  :style="{
                    width: isMobile ? '100%' : '',
                  }"
                >
                  <div
                    v-if="!isSmallScreen"
                  >
                    <div v-if="item.item_sc === '00000000'">
                      <v-text-field
                        v-model="item.item_nm"
                        @blur="editItem(item)"
                        @keyup.enter="editItem(item)"
                        style="width: 700px;"
                      />
                    </div>
                    <div v-else>
                      {{ item.item_nm }}
                    </div>
                  </div>
                  <div
                  v-else
                  >
                    <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                      {{ item.item_nm }}
                    </div>
                    <div style="display: flex; align-items: center;">
                      <b>{{ formatPrice(item.sale_price) }}원</b>
                    </div>
                  </div>

                  <div style="
                    font-size: 0.75em;
                    font-weight: 300;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* 최대 2줄까지만 표시 */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    "
                    v-if="!isSmallScreen"
                  >
                    {{ item.item_detail }}
                  </div>
                  
                  <v-chip v-if="userPermission==='0' && item.qty > 0" color="red"  size="x-small">재고보유상품</v-chip>

                </div>
              </template>
              <template v-slot:[`item.sale_price`]="{ item }">
                <div style="width: 80px;" v-if="!isMobile">
                  <div v-if="item.item_sc === '00000000'">
                    <v-text-field
                      v-model="item.sale_price"
                      @blur="editItem(item)"
                      @keyup.enter="editItem(item)"
                    />
                  </div>
                  <div v-else>
                    <div>{{ formatPrice(item.sale_price) }}원</div>
                  </div>
                </div>
              </template>

              <template v-slot:[`item.action`]="{ item }">


                <v-btn v-if="!isMobile" small @click="add_cart_item(item)" color="primary" dark elevation="2">담기</v-btn>
                <v-btn v-if="isMobile" @click="add_cart_item(item)" style="padding: 8px; min-width: auto; border: 1px solid grey;" elevation="0">
                  <v-icon icon="mdi-plus" size="large" color="black"></v-icon>
                </v-btn>


              </template>
            </v-data-table>

      </v-card>

      <v-card
      v-if="!isMobile || goToCart"
      :height="imgHeight"
        class="card"
        elevation="3"
        style="
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
        "
        :style="{
          width: isMobile ? '100%' : '35%',
          margin: isMobile ? '0 0 0 5px' : '0px 5px 5px 5px',
        }"
        >
        <div
          style="height: 72px; align-items: start;"
          v-if="isMobile"
            :style="{
            width: isMobile ? '100%' : '',
            backgroundColor: isMobile ? '#333333' : ''
          }">
          <v-card-text
            style="
              display: flex;
              align-items: start;
            "
          >
            <v-btn v-if="isMobile" style="padding: 8px; min-width: auto; border: 1px solid grey; background-color: #333333;" elevation="0" @click="mobile_goToBack">
              <v-icon icon="mdi-arrow-left" size="large" color="white"></v-icon>
              <p style="color: white;">제품목록</p>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isMobile"
              style="
              color: white;
              background-color: #333333;
              "
              icon="mdi-reload"
            >
            </v-btn>
          </v-card-text>
        </div>

        <v-data-table-virtual
          v-model="selected"
          :headers="cartheaders"
          :items="cartItems"
          item-key="id"
          id="items-data-table"
          class="styled-table"
          :items-per-page="40"
          style="overflow-y: auto;"
          :height="rightCardHeight"
          no-data-text="장바구니가 비어 있습니다."
        >

          <template v-slot:headers>
          </template>
          <template v-slot:[`item.item_image_link`]="{ item, index }">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
            <v-icon
              v-if="index !== 0 && userPermission === '0' && cart_order_control === true"
              icon="mdi-arrow-up-thick" 
              size="small" 
              color="purple" 
              @click="moveItemUp(index)"></v-icon>
            <img :src="item.item_image_link" alt="Product Image" width="80" height="80" @click="openImage(item.item_nm, item.item_detail, item.item_image_link, item.item_detail_image_link)"
              style="cursor: pointer;"
            />
            </div>
          </template>
          <template v-slot:[`item.item_nm`]="{ item }">
            <div
              style="
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
              "
              :style="{
                width: isMobile ? '100%' : '',
              }"
            >

              <div
              v-if="!isSmallScreen"
                style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;
                width: 100%;
                "
              >
                <p style="font-size: 12px;"> {{ item.item_category }}<br>{{ item.item_nm }}</p>

                <div style="display: flex; align-items: center;">
                  <div
                    style="border: 1px solid gray; width: 20px; text-align: center;"
                  >
                    <v-icon size="x-small" icon="mdi-minus"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cart_item_minus_qty(item)"
                    ></v-icon>
                  </div>
                  <div
                    style="border: 1px solid gray; width: 30px; text-align: center;"
                  >
                    {{ item.cart_qty }}
                  </div>
                  <div
                    style="border: 1px solid gray; width: 20px; text-align: center;"
                  >
                    <v-icon size="x-small" icon="mdi-plus"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cart_item_plus_qty(item)"
                    ></v-icon>
                  </div>
                  

                  <v-spacer></v-spacer>
                  <b>{{ formatPrice(item.sum_price) }}</b>원
                  <v-icon size="x-small" icon="mdi-close"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cancel_cart_item(item)"
                  ></v-icon>
                </div>
              </div>
              <div
              v-else
              >
              <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                {{ item.item_nm }}
              </div>
                <div style="align-items: center;">
                  <b>{{ formatPrice(item.sale_price) }}원</b>  
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.sale_price`]="{ item }">
            <div v-if="!isMobile">
              <div>{{ formatPrice(item.sale_price) }}원 
                <v-number-input
                :reverse="false"
                controlVariant="default"
                label=""
                :hideInput="false"
                :inset="false"
                variant="outlined"
              ></v-number-input>
              </div>
            </div>
          </template>

          <template v-slot:bottom>
          </template>

        </v-data-table-virtual>

        <v-card-text
          style="
            width: 100%;
            height: 40px;
            background-color: #F8F8F8;
            border-top: #C4C4C4 solid 2px;
            "
        >
          <div
            style="
              display: flex;
              align-items: center;
            "
          >
          <span style="padding-right: 5px;">총 금액</span>
          <span>
            <span style="font-size: 22px; font-weight: 800;">{{ formatPrice(cartTotalPrice) }}</span>원</span>
            <v-spacer></v-spacer>
            <v-btn variant="outlined"
              v-if="!isMobile && userPermission === '0'"
              style="
              border-color: #C4C4C4;
              color: black;
              "
              @click="open_cart_solt_by"
            >
              장바구니정렬
            </v-btn>
            <v-btn variant="outlined"
              style="
              border-color: #C4C4C4;
              color: black;
              "
              @click="open_cust_info_input_dialog"
            >
              견적서출력
            </v-btn>
            <v-btn
              v-if="!isMobile"
              variant="outlined"
              style="
              border-color: #C4C4C4;
              color: black;
              "
              @click="cancel_all_cart_item()"
            >
              견적초기화
            </v-btn>

            <v-dialog
              width="800px"
              v-model="cust_info_input_dialog"
            >
              <v-card
                prepend-icon="mdi-account"
                title="고객정보"
              >
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field
                        v-model="insertCustNm"
                        label="성함*"
                        solo-inverted
                        hide-details
                        clearable
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="4" sm="6">
                      <v-select
                        label="전화번호*"
                        v-model="insertCustNumberinput1"
                        :items="NumberAreaCode"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="insertCustNumberinput2"
                        solo-inverted
                        hide-details
                        clearable
                        @input="limitInput"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="insertCustNumberinput3"
                        solo-inverted
                        hide-details
                        clearable
                        @input="limitInput"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

              <v-card-text tyle="display: flex; justify-content: flex-end;">
                <v-container fluid>
                  <v-checkbox v-model="contactCheck">
                    <template v-slot:label>
                      <div>
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props }">
                            <span
                              v-bind="props"
                              @click="policy_personal_informationToggle"
                              style="cursor: pointer; text-decoration: underline; color: blue;"
                            >
                              개인정보 수집 및 이용
                            </span>
                          </template>
                          개인정보취급방침
                        </v-tooltip>
                        에 동의합니다
                      </div>
                    </template>
                  </v-checkbox>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="orange"
                  @click="open_estimate_dialog"
                  style="
                  font-weight: 900;
                  "
                >견적서 미리보기</v-btn>

              </v-card-actions>

              </v-card>
            </v-dialog>

            <v-dialog
              width="300px"
              v-model="cart_solt_by_dialog"
            >
              <v-card
                prepend-icon="mdi-account"
                title="정렬순서"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-btn variant="outlined"
                        style="
                        border-color: #C4C4C4;
                        color: black;
                        "
                        @click="cartOrderbyPCparts"
                      >
                        구성순 정렬
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-btn variant="outlined"
                        :prepend-icon="cart_order_control ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                        style="
                        border-color: #C4C4C4;
                        color: black;
                        "
                        @click="cartOrderControlToggle"
                      >
                        정렬순서조정
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="orange"
                  @click="open_cart_solt_by"
                  style="
                  font-weight: 900;
                  "
                >닫기</v-btn>

              </v-card-actions>

              </v-card>
            </v-dialog>

            <v-dialog
              width="max-content" height="max-content"
              v-model="estimate_dialog"
            >
            <EstimateManagementPrint 
              :selectedRowData="{
                insertCustNm: insertCustNm,
                insertCustNumber: insertCustNumberinput1+'-'+insertCustNumberinput2+'-'+insertCustNumberinput3,
                cartItems: cartItems
              }" />
            </v-dialog>

          </div>
        </v-card-text>

      </v-card>

    </v-card>

  </v-card>

  <v-dialog
    width="max-content" height="max-content"
    v-model="policy_personal_information"
  >
    <v-card>
      <PersonalInformation />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="surface-variant"
          text="닫기"
          variant="flat"
          @click="policy_personal_informationToggle"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="snackbarOn"
    :timeout="500"
    :color="snackbarMessageColor"
    rounded="pill"
  >
    {{ snackbarMessage }}
  </v-snackbar>
  
</template>
<script>
import EstimateManagementPrint from '@/components/common/templete/PrintFormatEsti.vue';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import PersonalInformation from '@/components/common/policy/personal_information.vue';

export default {
  props: {
    userPermission: {
      type: String,
      default: null,
    }
  },
  components: {
    EstimateManagementPrint,
    PersonalInformation,
  },
  setup(props) {
    const items = ref([]);
    const itemsPage = ref(0);
    const isMobile = ref(false);
    const isSmallScreen = ref(false);
    const itemSearch = ref('');
    const loading = ref(true);
    const imgHeight = ref(0);
    const rightCardHeight = ref(0);
    const panel = ref(0);
    const snackbarOn = ref(false);
    const snackbarMessage = ref(null);
    const snackbarMessageColor = ref(null);
    const cust_info_input_dialog = ref(false);
    const estimate_dialog = ref(false);
    const goToCart = ref(false);
    const categoryNm = ref ('');
    const categoryCount = ref('');
    
    const now = new Date();
      const year = now.getFullYear();
      const rightyear = String(year).slice(-2);
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
      const randomDigit = Math.floor(Math.random() * 10);

    const orderNo = ref(`${rightyear}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomDigit}`);
    const insert_check_orderNo = ref('');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const stockCheck = ref(false);
    const stockCheckIcon = ref('mdi-checkbox-blank-outline');
    const stockCheckChange = () => {
      stockCheck.value = !stockCheck.value;
      if(stockCheck.value) {
        usedCheck.value = false;
        updateusedIcon();
      }
      updateIcon();
      filterItems(selectCategory.value);
    };
    const updateIcon = () => {
      stockCheckIcon.value = stockCheck.value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline';
    };
    const usedCheck = ref(false);
    const usedCheckIcon = ref('mdi-checkbox-blank-outline');
    const usedCheckChange = () => {
      usedCheck.value = !usedCheck.value;
      if(usedCheck.value) {
        stockCheck.value = false;
        updateIcon();
      }
      updateusedIcon();
      filterItems(selectCategory.value);
    };
    const updateusedIcon = () => {
      usedCheckIcon.value = usedCheck.value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline';
    };


    const insertCustNm = ref('');
    const insertCustNumberinput1 = ref('010');
    const insertCustNumberinput2 = ref('');
    const insertCustNumberinput3 = ref('');
    const NumberAreaCode = ref(['010','011','016','017','018','019','02','031','032','033','041','042','043','044','051','052','053','054','055','061','062','063','064','0130','070','080','0506','0505','0507','0504','0503','0502','0303','050','0508']);
    const contactCheck = ref(false);

    const limitInput = () => {
      if (insertCustNumberinput2.value === null) {
        insertCustNumberinput2.value === '';
      }
      if (insertCustNumberinput3.value === null) {
        insertCustNumberinput3.value === '';
      }
      // 숫자만 남기기
      insertCustNumberinput2.value = insertCustNumberinput2.value.replace(/\D/g, '');
      insertCustNumberinput3.value = insertCustNumberinput3.value.replace(/\D/g, '');
      // 최대 4자리 제한
      if (insertCustNumberinput2.value.length > 4) {
        insertCustNumberinput2.value = insertCustNumberinput2.value.slice(0, 4);
      }
      if (insertCustNumberinput3.value.length > 4) {
        insertCustNumberinput3.value = insertCustNumberinput3.value.slice(0, 4);
      }
    };

    const mobile_goToCart = () => {
      goToCart.value = true;
    };
    const mobile_goToBack = () => {
      goToCart.value = false;
    };

    const open_cust_info_input_dialog = async () => {
      if (!isMobile.value) {
        if (cartItems.value.length === 0) {
          snackbarOn.value = true;
          snackbarMessage.value = '장바구니에 상품을 담아주세요';
          snackbarMessageColor.value = 'red';
        } else {
          cust_info_input_dialog.value = true;
        }
      } else {
        snackbarOn.value = true;
        snackbarMessage.value = '현재는 PC에서만 출력이 가능합니다';
        snackbarMessageColor.value = 'red';
      }
    };

    const cart_solt_by_dialog = ref(false);
    const open_cart_solt_by = async () => {
      cart_solt_by_dialog.value = !cart_solt_by_dialog.value;
    };

    const open_estimate_dialog = async () => {
      save_estimate_check();
      if (insertCustNm.value === '' || insertCustNumberinput2.value === '' || insertCustNumberinput3.value === '') {
        snackbarOn.value = true;
        snackbarMessage.value = '고객정보가 입력되지않았습니다';
        snackbarMessageColor.value = 'red';
      } else if (!contactCheck.value) {
        snackbarOn.value = true;
        snackbarMessage.value = '개인정보 수집에 동의해주세요';
        snackbarMessageColor.value = 'red';
      } else {
        cust_info_input_dialog.value = false;
        estimate_dialog.value = true;
      }
    };

    const save_estimate_check = async () => {
      if (insertCustNm.value === '' || insertCustNumberinput2.value === '' || insertCustNumberinput3.value === '') {
        snackbarOn.value = true;
        snackbarMessage.value = '고객정보가 입력되지않았습니다';
        snackbarMessageColor.value = 'red';
      } else if (!contactCheck.value) {
        snackbarOn.value = true;
        snackbarMessage.value = '개인정보 수집에 동의해주세요';
        snackbarMessageColor.value = 'red';
      } else {
        insert_check_orderNo.value = orderNo.value;
        orderinsert();
      }
    };


    const calculateImgHeight = () => {
      if(isMobile.value) {
        imgHeight.value = window.innerHeight - 65 - 50;
        rightCardHeight.value = window.innerHeight - 260;
      } else if (!isMobile.value) {
        imgHeight.value = window.innerHeight - 65 - 50 - 40;
        rightCardHeight.value = window.innerHeight - 220;
      } else {
        imgHeight.value = window.innerHeight
        rightCardHeight.value = window.innerHeight
      }
    };


    const selectCategory = ref('1.');
    const selectCategoryChange = (category) => {
      selectCategory.value = category;
      filterItems(selectCategory.value);
    };
    
    const filteredItems = ref([]);
    const filterItems = (categoryPrefix) => {
      filteredItems.value = [];
      const filteredItemsTemp = items.value
        .filter(item => {
          if (categoryPrefix === '98.유지보수') {
            return item.category.startsWith(categoryPrefix)
          } else if (stockCheck.value) {
            return item.category.startsWith(categoryPrefix) && item.qty > 0 && item.brand !== 'USED';
          } else if (usedCheck.value) {
            return item.category.startsWith(categoryPrefix) && item.brand === 'USED';
          } else {
            return item.category.startsWith(categoryPrefix) && item.brand !== 'USED';
          }
        })
        .sort((a, b) => parseFloat(a.sale_price) - parseFloat(b.sale_price));

      // cartItems에 있는 item_id들을 모은 배열 생성
      const cartItemIds = cartItems.value.map(cartItem => cartItem.item_sc);

      // 필터링된 항목을 순회하며 cartItems에 같은 item_id가 없으면 filteredItems에 추가
      for (const item of filteredItemsTemp) {
        if (!cartItemIds.includes(item.item_sc)) {
          filteredItems.value.push(item);
        }
      }

      itemSearch.value = '';

      switch (categoryPrefix) {
        case '1.':
          categoryNm.value = 'CPU';
          break;
        case '2.':
          categoryNm.value = 'CPU쿨러';
          break;
        case '3.':
          categoryNm.value = '메인보드';
          break;
        case '4.':
          categoryNm.value = '메모리';
          break;
        case '5. SSD':
          categoryNm.value = 'SSD';
          break;
        case '5. HDD':
          categoryNm.value = 'HDD';
          break;
        case '6.':
          categoryNm.value = 'ODD(CD/DVD)';
          break;
        case '7.':
          categoryNm.value = '그래픽카드';
          break;
        case '8.':
          categoryNm.value = '파워서플라이';
          break;
        case '9.':
          categoryNm.value = '케이스';
          break;
        case '모니터 - ':
          categoryNm.value = '모니터';
          break;
        case '프린터 - ':
          categoryNm.value = '프린터';
          break;
        case '99. 소프트웨어':
          categoryNm.value = '소프트웨어';
          break;
        case '99.조립':
          categoryNm.value = '조립';
          break;
        case '쿨러 - 케이스':
          categoryNm.value = '쿨링팬';
          break;
        case '98.유지보수':
          categoryNm.value = '유지보수';
          break;
        default:
          categoryNm.value = ''; // 기본값
      }

      categoryCount.value = filteredItems.value.length;
    };

    const filterSearchQuery = computed(() => {
      if (!itemSearch.value.trim()) {
        return filteredItems.value; // 검색어 없으면 기존 filteredItems 반환
      }

      const searchTerm = itemSearch.value.toLowerCase();
      return filteredItems.value.filter(item =>
        item.item_nm.toLowerCase().includes(searchTerm) ||
        item.item_detail.toLowerCase().includes(searchTerm)
      );
    });

    const cartItems = ref([]);
    const add_cart_item = (item) => {
      if (cartItems.value.length >= 18) {
        snackbarOn.value = 'true';
        snackbarMessage.value = '장바구니의 최대수량을 초과하였습니다';
        snackbarMessageColor.value = 'red-darken-2';
        return;
      } else {

      const { item_id, item_sc, item_nm, sale_price, qty, item_image_link, item_detail, item_detail_image_link } = item;

      const parts = item_nm.split('] ');
      const category = parts[0].replace('[', '');
      const item_name = parts[1];

      const cart_qty = 1;
      const cartItem = {
        item_id,
        item_sc,
        item_image_link,
        item_category: category,
        item_nm: item_name,
        origin_item_nm: item_nm,
        sale_price,
        qty,
        item_detail,
        item_detail_image_link,
        cart_qty : 1,
        sum_price: sale_price * cart_qty,
        filteredItemIndex: -1,
      };
      // filteredItems에서 해당 item의 인덱스를 찾습니다.
      if (item_sc !== '00000000') {
        const index = filteredItems.value.findIndex(filteredItem => filteredItem.item_sc === item_sc);
        if (index !== -1) {
          cartItem.filteredItemIndex = index; // 인덱스를 cartItem에 기록합니다.
          filteredItems.value.splice(index, 1); // 해당 아이템을 filteredItems 배열에서 제거합니다.
        }
      }
        // cartItem을 cartItems 배열에 추가합니다.
        cartItems.value.push(cartItem);
        calculate_sum_item_price();
        snackbarOn.value = 'true';
        snackbarMessage.value = '장바구니에 추가되었습니다';
        snackbarMessageColor.value = 'blue';
      }
    };

    const cancel_cart_item = (item) => {
      // 취소할 아이템의 정보 추출
      const { item_id, item_sc, origin_item_nm, sale_price, qty, item_image_link, item_detail, filteredItemIndex } = item;
      // filteredItems에 다시 추가할 아이템 객체 생성
      const filteredItem = {
        item_id,
        item_sc,
        item_image_link,
        item_nm: origin_item_nm,
        sale_price,
        qty,
        item_detail,
      };

      // cartItems 배열에서 해당 아이템 제거
      if (item_sc !== '00000000') {
        // 일반 상품은 item_sc를 기준으로 제거
        const index = cartItems.value.findIndex(cartItem => cartItem.item_sc === item_sc);
        if (index !== -1) {
          cartItems.value.splice(index, 1);
        }
      } else if (item_sc === '00000000') {
        // item_sc가 '00000000'인 경우, item_id 외에 고유 조건 추가
        const index = cartItems.value.findIndex((cartItem, idx) => 
          cartItem.item_id === item_id && 
          cartItem.item_sc === '00000000' &&
          idx === cartItems.value.indexOf(item) // 배열 내의 순서로 고유성 확인
        );

        if (index !== -1) {
          cartItems.value.splice(index, 1);
        }
      }


      // filteredItems 배열에 다시 추가
      if (item_sc !== '00000000') {
        if (filteredItemIndex !== -1) {
          // filteredItemIndex에 지정된 위치에 아이템 추가
          filteredItems.value.splice(filteredItemIndex, 0, filteredItem);
        } else {
          // filteredItemIndex가 -1이라면 가장 끝에 추가
          filteredItems.value.push(filteredItem);
        }
      }
      calculate_sum_item_price();
    };

    const cancel_all_cart_item = () => {
      orderNo.value = `${rightyear}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomDigit}`;
      cartItems.value = [];
      calculate_sum_item_price();
      fetchData();
    };


    const cartTotalPrice = ref(0);
    const calculate_sum_item_price = () => {
      // 총금액 초기화
      cartTotalPrice.value = 0;
      // cartItems 배열 순회하여 sum_price를 합산
      for (const cartItem of cartItems.value) {
        cartTotalPrice.value += cartItem.sum_price;
      }
    };

    const cart_item_minus_qty = (item) => {
      if (item.cart_qty > 1) {
        item.cart_qty--;
        item.sum_price = item.sale_price * item.cart_qty;
        calculate_sum_item_price();
      } 
    };

    const cart_item_plus_qty = (item) => {
      if (item.cart_qty < 100) {
        item.cart_qty++;
        item.sum_price = item.sale_price * item.cart_qty;
        calculate_sum_item_price();
      } 
    };


    


    const headers = ref([
      { width: '100', align: 'center', title: '이미지', value: 'item_image_link'},
      { width: '80%', title: '상품명', value: 'item_nm'},
      { width: '100', title: '판매가', value: 'sale_price'},
    ]);
    const cartheaders = ref([
      { width: '100', align: 'center', title: '이미지', value: 'item_image_link'},
      { width: '100%', title: '상품명', value: 'item_nm'},
    ]);
    
    if (!isSmallScreen.value) {
      headers.value.push({ width: '100', title: '', value: 'action' });
    }

    const fetchData = async () => {
      loading.value = true;
      try {
          const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
          const apiKey = process.env.VUE_APP_VERIFY_KEY;
          const uuid = process.env.VUE_APP_VERIFY_UUID;

          // 재고이동상품
          const firstUrl = `${request}/menu/item/move/item/select?apiKey=${apiKey}&uuid=${uuid}`;
          const firstResponse = await axios.get(firstUrl);

          // 매장재고상품
          const secondUrl = `${request}/menu/admin/shop/item/select?apiKey=${apiKey}&uuid=${uuid}`;
          const secondResponse = await axios.get(secondUrl);

          // 결합변수
          const combinedData = [];

          if (props.userPermission === '0') {
            combinedData.push({
              item_id: '00000000',
              item_cd: '',
              brand: 'PCKOREA',
              category: '98.유지보수',
              item_sc: '00000000',
              item_nm: '[카테고리] 상품명', // 이름
              item_detail: '',
              item_image_link: 'https://st.depositphotos.com/1098692/1485/i/450/depositphotos_14850833-stock-photo-red-gift-box.jpg', // 이미지 링크 (필요 시 채움)
              qty: 100,
              sale_price: '0',
              item_detail_image_link: '',
            });
          }

          // api 호출후 push
          if (secondResponse.data && secondResponse.data.selectData) {
              combinedData.push(...secondResponse.data.selectData.map(item => ({
                  item_id: item.item_id,
                  item_cd: item.item_cd,
                  brand: item.brand,
                  category: item.category,
                  item_sc: item.item_sc,
                  item_nm: item.item_nm,
                  item_detail: item.item_detail,
                  item_image_link: item.item_image_link,
                  qty: item.qty,
                  sale_price: item.sale_price,
                  item_detail_image_link: item.item_detail_image_link
              })));
          }

          // api 호출후 push
          if (firstResponse.data && firstResponse.data.selectData) {
              // Filter out items from the first response that already exist in the combined data
              const itemScSet = new Set(combinedData.map(item => item.item_sc));
              const filteredItems = firstResponse.data.selectData.filter(item => !itemScSet.has(item.item_sc));
              combinedData.push(...filteredItems.map(item => ({
                  brand: item.brand,
                  category: item.category,
                  item_sc: item.item_sc,
                  item_nm: item.brand === 'USED' ? item.item_nm.replace(/^\[/, '[중고 ') + ' - 무상1개월보증' : item.item_nm,
                  item_detail: item.item_detail,
                  item_image_link: item.item_image_link,
                  qty: item.qty,
                  sale_price: item.sale_price,
                  item_detail_image_link: item.item_detail_image_link
              })));
          }

          combinedData.sort((a, b) => {
            // category를 먼저 정렬
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;

            // category가 같은 경우, sale_price를 숫자로 변환하여 기준으로 정렬
            const salePriceA = parseFloat(a.sale_price);
            const salePriceB = parseFloat(b.sale_price);

            if (salePriceA < salePriceB) return -1;
            if (salePriceA > salePriceB) return 1;

            return 0;
          });

          items.value = combinedData;
          loading.value = false;
          
          itemsPage.value = Math.round(imgHeight.value / 87)-1;
          filterItems(selectCategory.value);

      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

    const formatPrice = (price) => {
      // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
      const formattedPrice = Number(price).toLocaleString('ko-KR');
      return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
    };


    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
      isSmallScreen.value = window.innerWidth <= 1300;
    };

    const openImage = (itemname, itemdetail, itemimagelink, itemdetailimagelink) => {
        const htmlContent = `
          <html>
            <head>
              <title>${itemname}</title>
            </head>
            <body style="display: flex; justify-content: center; align-items: center;">
            <center>
              ${itemdetailimagelink}
            </center>
            </body>
          </html>
        `;

        const imageWindow = window.open('', '_blank', 'width=920,height=2000');
        imageWindow.document.write(htmlContent);
        imageWindow.document.close();
      };


      const orderinsert = () => {
        calculate_sum_item_price();

        // 현재 시간과 날짜를 함수 내에서 다시 계산
        const now = new Date();
        const year = now.getFullYear();
        const rightyear = String(year).slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
        const randomDigit = Math.floor(Math.random() * 10);

        // 새롭게 계산된 값을 이용해 orderNo 갱신
        orderNo.value = `${rightyear}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomDigit}`;

        const data = {
          esti_no:                orderNo.value,
          cust_id:                null,
          cust_type:              null,
          order_date:             formattedDate,
          order_person_name:      insertCustNm.value,
          order_person_phone:     insertCustNumberinput1.value+'-'+insertCustNumberinput2.value+'-'+insertCustNumberinput3.value,
          order_person_phone2:    null,
          ordered_products:       null,
          order_memo:             null,
          receipt_person_name:    null,
          receipt_person_phone:   null,
          receipt_person_phone2:  null,
          receipt_zip:            null,
          receipt_addr1:          null,
          receipt_addr2:          null,
          delivery_memo:          null,
          order_item_amount:      cartTotalPrice.value,
          order_delivery_amount:  0,
          order_amount:           cartTotalPrice.value,
          create_date:            formattedDate,
          row_state:              0,
        };

        const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
        const url = `${request}/menu/order/shop/esti/insert`;

        // Axios를 사용하여 POST 요청 보내기
        axios.post(url, data)
          .then(response => {
            // 성공적으로 응답을 받았을 때 수행할 작업
            console.log('견적이 성공적으로 등록되었습니다.', response.data);
            orderiteminsert();
          })
          .catch(error => {
            // 오류 발생 시 처리
            console.error('주문 등록 실패:', error.response.data);
            // 오류 메시지 표시 등의 작업 수행
          });
      };

      const orderiteminsert = () => {
        cartItems.value.forEach((item, index) => {
          const data = {
            esti_no:           orderNo.value,
            esti_seq:          index + 1,
            item_id:           item.item_id,
            item_cd:           null,
            item_category:     item.item_category,
            item_sc:           item.item_sc,
            item_nm:           item.item_nm,
            item_price:        item.sale_price,
            qty:               item.cart_qty,
            delivery_price:    null,
            order_item_amount: item.sale_price * item.cart_qty,
            delivery_number:   null,
            row_state:         0,
        };

        const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
        const url = `${request}/menu/order/shop/estiiem/insert`;

        axios.post(url, data)
          .then(response => {
            console.log('주문상품정보가 성공적으로 등록되었습니다.', response.data);
          })
          .catch(error => {
            // 오류 발생 시 처리
            console.error('주문 등록 실패:', error.response.data);
            // 오류 메시지 표시 등의 작업 수행
          });
        });
      };


    const policy_personal_information = ref(false);
    const policy_personal_informationToggle = () => {
      policy_personal_information.value = !policy_personal_information.value
    };

    const editingItem = ref(null);
    const isEditing = (item) => {
      return editingItem.value && editingItem.value.id === item.id; // 편집 중인 항목 확인
    };

    const editItem = (item) => {
      if (item.item_nm === '수기등록상품') {
        editingItem.value = item; // 클릭한 항목을 편집 중으로 설정
      }
    };

    const cart_order_control = ref(false);
    const cartOrderControlToggle = () => {
      cart_order_control.value = !cart_order_control.value;
    };
    

    const moveItemUp = (index) => {
      if (index > 0) {
        const temp = cartItems.value[index - 1];
        cartItems.value.splice(index - 1, 1, cartItems.value[index]);
        cartItems.value.splice(index, 1, temp);
      }
    };

    // PC 부품 정렬 우선순위 배열
    const categoryOrder = [
      "CPU",
      "CPU쿨러",
      "메인보드",
      "메모리",
      "그래픽카드",
      "SSD",
      "HDD",
      "파워서플라이",
      "ODD",
      "케이스",
      "쿨링팬",
    ];

    // 정렬 메서드
    const cartOrderbyPCparts = () => {
      cartItems.value.sort((a, b) => {
        const aIndex = categoryOrder.indexOf(a.item_category);
        const bIndex = categoryOrder.indexOf(b.item_category);

        // categoryOrder에 없는 경우, 순서를 뒤로 밀리게 설정
        const aOrder = aIndex === -1 ? Infinity : aIndex;
        const bOrder = bIndex === -1 ? Infinity : bIndex;

        return aOrder - bOrder;
      });
    };

    onMounted( async () => {
      checkIsMobile();
      fetchData().then(() => {
      });
      calculateImgHeight();
      window.addEventListener('resize', checkIsMobile);
      window.addEventListener('resize', calculateImgHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkIsMobile);
      window.removeEventListener('resize', calculateImgHeight);
    });
    return {
      imgHeight, rightCardHeight, filteredItems, snackbarMessage, panel, goToCart, selectCategory,
      categoryNm, categoryCount, cartItems, cartTotalPrice, contactCheck, formattedDate,
      headers, cartheaders, items, itemSearch, loading, itemsPage, estimate_dialog, cust_info_input_dialog, orderNo,
      insertCustNm, insertCustNumberinput1, insertCustNumberinput2, insertCustNumberinput3, NumberAreaCode, insert_check_orderNo,
      stockCheck, stockCheckIcon, stockCheckChange, updateIcon, orderinsert, orderiteminsert, selectCategoryChange,
      fetchData, formatPrice, openImage, filterItems, open_estimate_dialog, limitInput, save_estimate_check, usedCheckIcon, usedCheckChange, updateusedIcon,
      add_cart_item, calculate_sum_item_price, cancel_cart_item, cancel_all_cart_item, open_cust_info_input_dialog,
      mobile_goToBack, mobile_goToCart, snackbarMessageColor, policy_personal_information, policy_personal_informationToggle,
      cart_item_minus_qty, cart_item_plus_qty, snackbarOn, editingItem, isEditing, editItem, usedCheck, moveItemUp,
      open_cart_solt_by, cart_solt_by_dialog, cart_order_control, cartOrderControlToggle, cartOrderbyPCparts, filterSearchQuery,
      isMobile: computed(() => isMobile.value),
      isSmallScreen: computed(() => isSmallScreen.value),
      isDesktop: computed(() => !isMobile.value),
    };
  },
};
</script>
<style>

</style>
